//폰트
@font-face {
  font-family: 'EliceDigitalBaeum-Bd';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_elice@1.0/EliceDigitalBaeum-Bd.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;700&display=swap');
//초기화
body,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
ol,
p {
  margin: 0;
  padding: 0;
  line-height: 1;
}
* {
  transition: 0.2s;
}
ul,
ol,
li {
  list-style: none;
}
button {
  background: 0 none;
  border: 0 none;
}
.inner {
  max-width: 950px;
  margin: 0 auto;
  @media screen and (max-width: 1300px) {
    max-width: 880px;
  }
  @media screen and (max-width: 800px) {
    max-width: 400px;
  }
  @media screen and (max-width: 499px) {
    max-width: 350px;
  }
}
.title {
  @media screen and (max-width: 800px) {
    display: none;
  }
}
.m_title {
  display: none;
  width: 100%;
  @media screen and (max-width: 800px) {
    display: block;
  }
}
.App {
  font-family: 'Noto Sans KR', sans-serif;
  letter-spacing: -5%;
  .body {
    overflow: hidden;
    position: relative;
    .header {
      background: url('../public/images/header_bg.png') no-repeat no-repeat center top/cover;
      padding-top: 30px;
      width: 100%;
      height: 750px;
      position: relative;
      @media screen and (max-width: 1500px) {
        height: calc(100vw * (750 / 1500));
      }
      @media screen and (max-width: 1300px) {
        height: calc(100vw * (680 / 1300));
      }
      @media screen and (max-width: 1000px) {
        height: calc(100vw * (500 / 1000));
        padding: 0;
      }
      @media screen and (max-width: 800px) {
        background-image: url('../public/images/m_header_bg.svg');
        height: calc(100vw * (630 / 800));
      }
      .man {
        background: url('../public/images/man.svg') no-repeat center;
        position: absolute;
        left: 50%;
        margin-left: -570px;
        bottom: 5%;
        width: 224px;
        height: 228px;
        background-size: 100%;
        animation: person 5s infinite linear;
        @media screen and (max-width: 1500px) {
          margin-left: -550px;
        }
        @media screen and (max-width: 1300px) {
          margin-left: calc(100vw * (-500 / 1300));
          width: calc(100vw * (230 / 1300));
        }
        @media screen and (max-width: 800px) {
          top: 67%;
          margin-left: calc(100vw * (-350 / 800));
          width: calc(100vw * (150 / 800));
        }
        @media screen and (max-width: 500px) {
          top: 55%;
        }
        @media screen and (max-width: 499px) {
          margin-left: -180px;
        }
      }
      .woman {
        background: url('../public/images/woman.svg') no-repeat center;
        position: absolute;
        right: 50%;
        bottom: 0%;
        width: 300px;
        height: 362px;
        margin-right: -600px;
        background-size: 100%;
        animation: person 6s infinite linear;
        @media screen and (max-width: 1500px) {
          width: calc(100vw * (300 / 1500));
          margin-right: -550px;
        }
        @media screen and (max-width: 1300px) {
          top: 35%;
          margin-right: calc(100vw * (-600 / 1300));
          width: calc(100vw * (280 / 1300));
        }
        @media screen and (max-width: 800px) {
          top: 40%;
          margin-right: calc(100vw * (-350 / 800));
          width: calc(100vw * (200 / 800));
        }
        @media screen and (max-width: 499px) {
          width: 110px;
          margin-right: -200px;
          top: 160px;
          height: 230px;
        }
      }
      .nav_box {
        overflow: hidden;
        .logo {
          float: left;
          img {
            margin-bottom: 10px;
            display: block;
          }
        }
        .nav {
          float: right;
          ul {
            .one_d {
              cursor: pointer;
              display: inline-block;
              color: #fff;
              font-weight: 700;
              font-size: 16px;
              margin-left: 40px;
            }
            .metaverse_btn {
              margin-left: 26px;
              height: 37px;
              line-height: 37px;
              padding: 0 14px;
              background-color: #00aeef;
              color: #000;
              border-radius: 30px;
            }
          }
        }
        @media screen and (max-width: 1000px) {
          display: none;
        }
      }
      .m_nav_box {
        display: none;
        padding-bottom: 40px;
        .nav_btn {
          position: absolute;
          left: 20px;
          top: 20px;
          background: url('../public/images/open_btn.png') no-repeat;
          width: 40px;
          height: 40px;
        }
        ul {
          display: none;
          position: absolute;
          left: 0;
          top: 0;
          width: 340px;
          height: 500px;
          // height: 350px;
          background-color: #5c1f78;
          z-index: 100;
          padding: 24px 21px 24px 37px;
          box-sizing: border-box;
          color: #fff;
          &.on {
            display: block;
          }
          .close_btn {
            background: url('../public/images/close_btn.png') no-repeat right;
            width: 100%;
            height: 20px;
            cursor: pointer;
            margin-bottom: 54px;
          }
          .one_d {
            cursor: pointer;
            display: block;
            margin-bottom: 54px;
            font-size: 20px;
            font-weight: 700;
          }
        }
        @media screen and (max-width: 1000px) {
          display: block;
        }
      }

      .header_main {
        text-align: center;
        margin-top: 30px;
        @media screen and (max-width: 800px) {
          margin-top: 80px;
        }
        @media screen and (max-width: 700px) {
          margin-top: 60px;
        }
        @media screen and (max-width: 600px) {
          margin-top: 50px;
        }
        @media screen and (max-width: 499px) {
          margin-top: 30px;
        }
        .header_title {
          display: inline-block;
          @media screen and (max-width: 800px) {
            margin-left: 0;
          }
          img {
            @media screen and (max-width: 1500px) {
              width: calc(100vw * (1000 / 1500));
            }
            @media screen and (max-width: 1300px) {
              width: calc(100vw * (900 / 1300));
            }
            @media screen and (max-width: 800px) {
              display: none;
            }
          }
          .m_header_title {
            display: none;
            @media screen and (max-width: 800px) {
              display: block;
              width: calc(100vw * (630 / 800));
            }
          }

          p {
            font-size: 20px;
            margin-top: 15px;
          }
        }
        .txt {
          margin-top: 30px;
          @media screen and (max-width: 1300px) {
            margin-top: 20px;
          }
          @media screen and (max-width: 500px) {
            margin-top: 12px;
          }
          img {
            @media screen and (max-width: 1300px) {
              width: calc(100vw * (400 / 1300));
            }
            @media screen and (max-width: 800px) {
              width: calc(100vw * (350 / 800));
            }
            @media screen and (max-width: 499px) {
              width: 170px;
            }
          }

          .data_txt {
            margin-top: 15px;
            font-size: 34.69px;
            @media screen and (max-width: 1300px) {
              font-size: 25px;
            }
            @media screen and (max-width: 800px) {
              margin-top: 15px;
            }
            @media screen and (max-width: 499px) {
              margin-top: 3px;
            }
            @media screen and (max-width: 499px) {
              font-size: 20px;
            }
            img {
              @media screen and (max-width: 1500px) {
                width: calc(100vw * (400 / 1500));
              }
              @media screen and (max-width: 1000px) {
                width: calc(100vw * (250 / 1000));
              }
              @media screen and (max-width: 800px) {
                width: calc(100vw * (400 / 1000));
              }
              @media screen and (max-width: 499px) {
                margin-top: 5px;
                width: calc(100vw * (200 / 499));
              }
            }
            span {
              font-size: 40px;
              font-weight: 700;
              @media screen and (max-width: 1300px) {
                font-size: 30px;
              }
              @media screen and (max-width: 800px) {
                font-size: calc(100vw * (30 / 800));
              }
              @media screen and (max-width: 499px) {
                margin-top: 10px;
                font-size: 20px;
              }
            }
          }
        }

        .letter_position {
          position: absolute;
          left: 50%;
          top: 650px;
          transform: translateX(-50%);
          z-index: 5;
          transition: all 0.1 ease;
          @media screen and (max-width: 1500px) {
            top: calc(100vw * (630 / 1500));
          }
          @media screen and (max-width: 1300px) {
            top: calc(100vw * (550 / 1300));
          }
          @media screen and (max-width: 1000px) {
            top: calc(100vw * (460 / 1300));
          }
          @media screen and (max-width: 800px) {
            top: calc(100vw * (480 / 800));
          }
          @media screen and (max-width: 600px) {
            top: calc(100vw * (340 / 600));
          }
          @media screen and (max-width: 499px) {
            top: calc(100vw * (260 / 499));
          }
          .arrow_box {
            background: url('../public/images/arrow.png ') no-repeat center top;
            width: 50px;
            height: 50px;
            position: absolute;
            left: 50%;
            top: -55px;
            margin-left: -25px;
            animation: arrow 1.5s infinite linear;
            opacity: 1;
            transition: opacity 0.3s ease;
            @media screen and (max-width: 1500px) {
              top: -35px;
            }
            @media screen and (max-width: 1300px) {
              top: -25px;
              background-size: 70%;
            }
            @media screen and (max-width: 1100px) {
              top: -10px;
              background-size: 65%;
            }
            @media screen and (max-width: 1000px) {
              top: 5px;
              background-size: 55%;
            }
            @media screen and (max-width: 800px) {
              top: 0px;
              background-size: 60%;
            }
            @media screen and (max-width: 500px) {
              top: 10px;
              background-size: 50%;
            }
            @media screen and (max-width: 499px) {
              background-size: 25px;
              top: 15px;
            }
          }
          .letter_box {
            display: inline-block;
            position: relative;
            background: url('../public/images/close_letter.svg') no-repeat center;
            height: 200px;
            width: 250px;
            cursor: pointer;
            @media screen and (max-width: 1500px) {
              background-size: calc(100vw * (230 / 1500));
            }
            @media screen and (max-width: 1300px) {
              background-size: calc(100vw * (220 / 1300));
            }
            @media screen and (max-width: 800px) {
              background-size: 170px;
            }
            @media screen and (max-width: 600px) {
              background-size: 150px;
            }
            @media screen and (max-width: 499px) {
              transition: none;
              background-size: 130px;
            }
          }
          &:hover {
            .rocket {
              animation: rocket 1s 1 ease;
              animation-fill-mode: both;
            }
            .arrow_box {
              opacity: 0;
            }
            .letter_box {
              background-image: url('../public/images/open_letter.svg');
              height: 210px;
              @media screen and (max-width: 499px) {
                background-size: 155px;
              }
              & .letter {
                bottom: 10px;
                margin-left: -101px;
                @media screen and (max-width: 499px) {
                  bottom: 24px;
                }
              }
            }
          }
        }
      }
    }
    .main {
      text-align: center;
      .paly_box_bg {
        background: url('../public/images/paly_bg.svg') no-repeat center top/cover;
        width: 100%;
        height: 700px;
        margin-bottom: 200px;
        @media screen and (max-width: 1500px) {
          height: calc(100vw * (500 / 1500));
          margin-bottom: 300px;
        }
        @media screen and (max-width: 1300px) {
          height: calc(100vw * (500 / 1300));
          margin-bottom: 180px;
        }
        @media screen and (max-width: 1000px) {
          margin-bottom: 150px;
        }
        @media screen and (max-width: 800px) {
          margin-bottom: 100px;
          height: calc(100vw * (400 / 800));
          background: none;
        }
        @media screen and (max-width: 499px) {
          height: auto;
          margin-bottom: 50px;
        }
        .inner {
          @media screen and (max-width: 800px) {
            max-width: 500px;
          }
        }
        .paly_box {
          margin-top: 300px;
          display: inline-block;
          position: relative;
          @media screen and (max-width: 1500px) {
            margin-top: 200px;
          }
          @media screen and (max-width: 1300px) {
            margin-top: 130px;
          }
          @media screen and (max-width: 800px) {
            margin-top: 100px;
          }
          .paly_vod {
            width: 760px;
            border: 1px solid #107bc0;
            height: auto;
            position: relative;
            @media screen and (max-width: 1500px) {
              width: calc(100vw * (800 / 1500));
            }
            @media screen and (max-width: 800px) {
              width: calc(100vw * (1000 / 1500));
            }
            @media screen and (max-width: 499px) {
              width: 300px;
            }
          }
        }
      }
      .summary_box {
        background: url('../public/images/summary_bg.svg') no-repeat;
        max-width: 100%;
        background-size: 100%;
        height: 980px;
        height: calc(100vw * (980 / 1920));
        position: relative;
        @media screen and (max-width: 1300px) {
          background-image: url('../public/images/summary_m.svg');
          height: calc(100vw * (940 / 1300));
        }
        .inner {
          height: calc(100vw * (980 / 1920));
          .summary_txt {
            text-align: left;
            position: absolute;
            top: 50%;
            left: 35%;
            transform: translate(-35%, -50%);
            @media screen and (max-width: 1300px) {
              left: 10%;
              transform: translate(-10%, -50%);
            }
          }
          img {
            display: inline-block;
            @media screen and (max-width: 1720px) {
              width: calc(100vw * (550 / 1720));
            }

            @media screen and (max-width: 1300px) {
              width: calc(100vw * (680 / 1300));
            }
          }
        }
      }
      .info_box {
        padding-top: 127px;
        @media screen and (max-width: 1300px) {
          padding-top: 80px;
        }
        @media screen and (max-width: 1000px) {
          padding-top: 50px;
        }
        @media screen and (max-width: 800px) {
          padding-top: 46px;
        }
        .title {
          margin-bottom: 40px;
          @media screen and (max-width: 1300px) {
            width: calc(100vw * (800 / 1300));
          }
          @media screen and (max-width: 1000px) {
            width: calc(100vw * (600 / 800));
          }
          @media screen and (max-width: 800px) {
            width: calc(100vw * (700 / 800));
          }
          @media screen and (max-width: 499px) {
            width: calc(100vw * (700 / 800));
          }
        }
        .info_box_bg {
          background: url('../public/images/info_bg_ex.svg ') no-repeat center;
          width: 100%;
          background-size: 100%;
          @media screen and (max-width: 1500px) {
          }
          @media screen and (max-width: 1300px) {
            background-position: 0 40%;
          }
          @media screen and (max-width: 800px) {
            background-image: url('../public/images/info_800px_bg.svg ');
            background-position: 0;
          }
          @media screen and (max-width: 675px) {
            background-image: url('../public/images/m_info_bg.svg ');
            background-size: 100%;
          }
          @media screen and (max-width: 499px) {
            padding: 0;
            background-position: 0 45%;
          }
          .info_list_box {
            margin-bottom: 70px;
            position: relative;
            &::after {
              content: '';
              width: 246px;
              height: 343.32px;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              background: url('../public/images/info_center.svg ') no-repeat center;
              @media screen and (max-width: 800px) {
                display: none;
              }
            }
            @media screen and (max-width: 499px) {
              margin-top: 20px;
            }
            .list_bg_1 {
              position: relative;
              background: url('../public/images/info_list_1.svg ') no-repeat center top/cover;
            }
            .list_bg_2 {
              background: url('../public/images/info_list_2.svg ') no-repeat center top/cover;
            }
            .list_bg_3 {
              margin-top: 60px;
              background: url('../public/images/info_list_3.svg ') no-repeat center top/cover;
              @media screen and (max-width: 1300px) {
                margin-top: 80px;
              }
              @media screen and (max-width: 800px) {
                margin-top: 0px;
              }
            }
            .list_bg_4 {
              background: url('../public/images/info_list_4.svg ') no-repeat center top/cover;
            }
            .list_bg {
              display: inline-block;
              position: relative;
              margin-bottom: 25px;
              width: 400px;
              height: 400px;
              @media screen and (max-width: 1300px) {
                width: 330px;
                height: 330px;
              }
              @media screen and (max-width: 499px) {
                width: 310px;
                height: 310px;
              }
              &:hover {
                animation: bounce 0.5s 1;
              }
            }
          }
        }
        .buttons {
          position: relative;
          text-align: center;
          padding-top: 80px;
          @media screen and (max-width: 1300px) {
            padding-bottom: 80px;
          }
          @media screen and (max-width: 1000px) {
            padding-top: 40px;
          }
          button {
            overflow: hidden;
            position: relative;
            display: inline-block;
            vertical-align: top;
            width: 293.78px;
            height: 67px;
            line-height: 67px;
            background: #00aeef;
            transition: all 0.5s;
            border-radius: 40.62px;
            &:last-child {
              margin-left: 51.06px;
            }
            @media screen and (max-width: 1300px) {
              width: 250px;
              line-height: 60px;
              height: 60px;
            }
            @media screen and (max-width: 657px) {
              margin: 12px 20px;
              &:last-child {
                margin-left: 20px;
              }
            }
            @media screen and (max-width: 499px) {
              width: 235.84px;
              height: 47.75px;
              line-height: 47.75px;
              margin: 0;
              margin-bottom: 12px;
              &:last-child {
                margin-left: 0;
              }
            }
            p {
              position: relative;
              color: #000;
              font-size: 24.37px;
              font-weight: 700;
              z-index: 2;
              @media screen and (max-width: 1300px) {
                font-size: 20px;
              }
              @media screen and (max-width: 499px) {
                font-size: 19px;
              }
            }

            &:hover {
              color: #fff;
            }
            &::before {
              content: '';
              z-index: -1;
              position: absolute;
              background: linear-gradient(90deg, #3db8d5 0.01%, #693fe0 100.09%);
              transition: all 1s;
              z-index: 1;
            }
          }
          .attend_btn {
            cursor: pointer;
            &::before {
              left: 0;
              top: 0;
              width: 0;
              height: 100%;
              border-bottom-right-radius: 50%;
              border-top-right-radius: 50%;
            }
            &:hover::before {
              width: 200%;
            }
          }
        }
      }
      .event_box {
        margin-top: 181px;
        height: auto;
        position: relative;
        background-color: #2a005a;
        padding: 181px 0 172px 0;
        &::after {
          content: '';
          display: block;
          padding: 5px;
        }
        @media screen and (max-width: 1100px) {
          margin-top: 100px;
          padding: 130px 0 130px 0;
        }
        @media screen and (max-width: 800px) {
          margin-top: 80px;
          padding: 100px 0 100px 0;
        }
        @media screen and (max-width: 580px) {
          margin-top: 40px;
          padding: 80px 0 80px 0;
        }
        @media screen and (max-width: 499px) {
          margin-top: 20px;
          padding: 36px 0 36px 0;
        }
        .inner {
          @media screen and (max-width: 1500px) {
            max-width: 800px;
          }
          @media screen and (max-width: 1200px) {
            max-width: 700px;
          }
          @media screen and (max-width: 800px) {
            max-width: 600px;
          }
          @media screen and (max-width: 650px) {
            max-width: 400px;
          }
          @media screen and (max-width: 499px) {
            max-width: 330px;
          }
          .event_title {
            @media screen and (max-width: 1500px) {
              width: 40%;
            }
            @media screen and (max-width: 1200px) {
              width: 35%;
            }
          }
        }
        .time_table {
          position: relative;
          margin-bottom: 150px;
          @media screen and (max-width: 1500px) {
            margin-bottom: 130px;
          }
          @media screen and (max-width: 1200px) {
            margin-bottom: 100px;
          }
          @media screen and (max-width: 800px) {
            margin-bottom: 50px;
          }
          img {
            width: 100%;
          }
          .lf_i {
            display: inline-block;
            position: absolute;
            left: -30%;
            bottom: 5%;
            width: 174px;
            height: 175px;
            background: url('../public/images/lf_i.png') no-repeat center;
            background-size: 100%;
            @media screen and (max-width: 1500px) {
              width: 150px;
              height: 150px;
              left: -25%;
            }
            @media screen and (max-width: 1200px) {
              width: 130px;
              height: 130px;
            }
            @media screen and (max-width: 1090px) {
              display: none;
            }
          }
          .top_i {
            display: inline-block;
            position: absolute;
            right: -20%;
            top: 0;
            width: 135px;
            height: 169px;
            background: url('../public/images/top_i.png') no-repeat center;
            background-size: 100%;
            @media screen and (max-width: 1500px) {
              width: 120px;
              height: 150px;
            }
            @media screen and (max-width: 1200px) {
              width: 100px;
              height: 120px;
            }
            @media screen and (max-width: 1090px) {
              display: none;
            }
            @media screen and (max-width: 800px) {
              right: -10%;
              width: 50px;
              height: 80px;
              display: block;
            }
            @media screen and (max-width: 499px) {
              top: -5%;
              right: -8%;
              width: 40px;
              height: 80px;
              display: block;
            }
          }
          .bottom_i {
            display: inline-block;
            position: absolute;
            right: -43%;
            bottom: -43%;
            width: 723px;
            height: 567px;
            background: url('../public/images/bottom_i.png') no-repeat center;
            background-size: 100%;
            @media screen and (max-width: 1500px) {
              bottom: -48%;
              width: 610px;
              height: 540px;
            }
            @media screen and (max-width: 1200px) {
              width: 550px;
              height: 500px;
            }
            @media screen and (max-width: 1090px) {
              display: none;
            }
          }
        }
        .work_day_list {
          margin-top: 125px;
          margin-bottom: 152px;
          position: relative;
          z-index: 9999;
          @media screen and (max-width: 1500px) {
            margin-top: 100px;
            margin-bottom: 130px;
          }
          @media screen and (max-width: 1200px) {
            margin-top: 80px;
            margin-bottom: 100px;
          }
          @media screen and (max-width: 800px) {
            z-index: 9999;
            margin-top: 50px;
            margin-bottom: 50px;
          }
          img {
            position: relative;
            z-index: 9999;
            width: 100%;
          }
          .work_lf_i {
            display: inline-block;
            position: absolute;
            left: -30%;
            top: 15%;
            width: 200px;
            height: 250px;
            background: url('../public/images/work_lf_i.png') no-repeat center;
            background-size: 100%;
            @media screen and (max-width: 1500px) {
              left: -25%;
              width: 150px;
              height: 200px;
            }
            @media screen and (max-width: 1200px) {
              left: -28%;
              width: 120px;
              height: 170px;
            }
            @media screen and (max-width: 1090px) {
              display: none;
            }
          }
          .work_bottom_i {
            display: inline-block;
            position: absolute;
            right: -55%;
            bottom: -12%;
            width: 731px;
            height: 860px;
            background: url('../public/images/work_bottom_i.png') no-repeat center;
            background-size: 100%;
            @media screen and (max-width: 1500px) {
              width: 620px;
              height: 800px;
            }
            @media screen and (max-width: 1200px) {
              width: 550px;
              height: 700px;
            }
            @media screen and (max-width: 1090px) {
              display: none;
            }
            @media screen and (max-width: 800px) {
              display: block;
              right: -10%;
              bottom: 75%;
              width: 156px;
              height: 122px;
              background-image: url('../public/images/mo_work_bottom_i.png');
            }
          }
        }
        .life_day_list {
          margin-top: 154px;
          position: relative;
          z-index: 9999;
          @media screen and (max-width: 1500px) {
            margin-top: 130px;
          }
          @media screen and (max-width: 1200px) {
            margin-top: 100px;
          }
          @media screen and (max-width: 800px) {
            margin-top: 50px;
          }
          img {
            width: 100%;
          }
          .life_lf_i {
            display: inline-block;
            position: absolute;
            left: -50%;
            top: 10%;
            width: 447px;
            height: 954px;
            background: url('../public/images/life_lf_i.png') no-repeat center;
            background-size: 100%;
            @media screen and (max-width: 1500px) {
              left: -45%;
              width: 330px;
              height: 954px;
            }
            @media screen and (max-width: 1200px) {
              left: -50%;
              width: 320px;
              height: 954px;
            }
            @media screen and (max-width: 1090px) {
              display: none;
            }
            @media screen and (max-width: 499px) {
              display: block;
              left: -0%;
              top: -10%;
              width: 40px;
              height: 40px;
              background-image: url('../public/images/mo_life_life_i.png');
            }
          }
          .life_bottom_i {
            display: inline-block;
            position: absolute;
            right: -25%;
            top: 15%;
            width: 135px;
            height: 255px;
            background: url('../public/images/life_bottom_i.png') no-repeat center;
            background-size: 100%;
            @media screen and (max-width: 1500px) {
              width: 100px;
              height: 255px;
            }
            @media screen and (max-width: 1200px) {
              width: 80px;
              height: 255px;
            }
            @media screen and (max-width: 1090px) {
              display: none;
            }
            @media screen and (max-width: 499px) {
              width: 40px;
              height: 40px;
              right: 8%;
              top: -14%;
              display: block;
              background-image: url('../public/images/mo_life_bottom_i.png');
            }
          }
        }
        .bottom_img {
          position: absolute;
          left: 0;
          bottom: 0;
          img {
            width: 100%;
          }
        }
        .pc_img {
          display: block;
          @media screen and (max-width: 800px) {
            display: none;
          }
        }
        .mo_img {
          display: none;
          @media screen and (max-width: 800px) {
            display: block;
            position: relative;
            z-index: 99999;
          }
        }
      }
      .partner_info {
        margin-bottom: 204px;
        margin-top: 194px;
        background-size: 100%;
        background: url('../public/images/partner_bg.svg') no-repeat center;
        @media screen and (max-width: 800px) {
          margin-top: 140px;
          margin-bottom: 100px;
        }
        @media screen and (max-width: 499px) {
          margin-top: 100px;
          margin-bottom: 50px;
        }
        .inner {
          max-width: 1200px;
          @media screen and (max-width: 1200px) {
            max-width: 900px;
          }
          @media screen and (max-width: 800px) {
            max-width: 500px;
          }
          .title-box {
            margin-bottom: 74px;
            display: inline-block;
            img {
              height: 100%;
            }
            @media screen and (max-width: 800px) {
              margin-bottom: 54px;
              height: 25px;
            }
            @media screen and (max-width: 499px) {
              height: 20px;
              margin-bottom: 40px;
            }
          }

          .list-box {
            .item {
              margin-bottom: 45px;
              display: inline-block;
              width: 360px;
              height: 77px;
              height: auto;
              @media screen and (max-width: 800px) {
                width: 220px;
              }
              @media screen and (max-width: 499px) {
                width: 150px;
              }
              img {
                width: 100%;
              }
            }
          }
          .support-box {
            margin-bottom: 139px;
            @media screen and (max-width: 800px) {
              margin-bottom: 50px;
            }
            @media screen and (max-width: 499px) {
              margin-bottom: 30px;
            }
            .item {
              margin-left: 55px;
              display: inline-block;
              &:first-child {
                margin-left: 0;
              }
              @media screen and (max-width: 800px) {
                margin-left: 25px;
                margin-bottom: 20px;
              }
              @media screen and (max-width: 499px) {
                margin-left: 25px;
                margin-bottom: 15px;
              }
            }
          }
          .company-box {
            .item {
              margin: 0 10px 35px 10px;
              @media screen and (max-width: 1200px) {
                margin-bottom: 25px;
              }
              @media screen and (max-width: 499px) {
                margin-bottom: 15px;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes bounce {
  from,
  to {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(0.9, 1.1);
  }
  50% {
    transform: scale(1.1, 0.9);
  }
  75% {
    transform: scale(0.95, 1.05);
  }
}

@keyframes rocket {
  from {
    transform: scale(0.3) translate3d(87%, -85%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(250%, -330%, 0);
    opacity: 1;
  }
}
@keyframes person {
  0%,
  100% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  50% {
    transform: rotate(-20deg);
  }
}
@keyframes arrow {
  0%,
  100% {
    transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
  }
  50% {
    transform: translateY(5px);
  }
}

.scroll-top {
  opacity: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  z-index: 9999;
  cursor: pointer;
  transition: opacity 0.3s ease;
  position: fixed;
  right: 30px;
  bottom: 30px;
  @media screen and (max-width: 800px) {
    right: 20px;
  }
  @media screen and (max-width: 499px) {
    right: 0px;
  }
  span {
    background: url('../public/images/up_icon.png') no-repeat center;
    display: inline-block;
    background-size: 100%;
    width: 60px;
    height: 60px;
    &:hover {
      background-image: url('../public/images/up_icon_hover.png');
    }
    @media screen and (max-width: 800px) {
      width: 50px;
      height: 50px;
    }
    @media screen and (max-width: 499px) {
      width: 40px;
      height: 40px;
    }
  }
}
.scroll-top.active {
  z-index: 99999;
  opacity: 1;
}

.scroll-top:hover,
.scroll-top:focus,
.scroll-top:active {
  outline: 0 none;
}
